var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('v-card', [_c('translation-locales'), _c('v-card-text', [_c('v-select', {
    attrs: {
      "disabled": !!_vm.resource,
      "items": _vm.distributorList,
      "item-text": "business_name",
      "item-value": "id",
      "label": "*Select Distributor",
      "outlined": "",
      "hide-details": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.distributor_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "distributor_id", $$v);
      },
      expression: "form.distributor_id"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Display Name",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.nicename,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nicename", $$v);
      },
      expression: "form.nicename"
    }
  }), _c('translatable-input', {
    staticClass: "mt-5",
    attrs: {
      "label": "Email Subject"
    },
    model: {
      value: _vm.form.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }), _c('translatable-textarea', {
    staticClass: "mt-5",
    attrs: {
      "label": "Email Content"
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  }), _c('p', [_vm._v("This supports custom formatting:")]), _c('ul', [_c('li', [_vm._v("[[first_name,last_name]] (First name, last name)")]), _c('li', [_vm._v("[[total_remain_reward_points]] Total remain healthcoin")]), _c('li', [_vm._v("[[distributor_slug_portal_url]] Distributor slug portal URL")]), _c('li', [_vm._v("[[reward_point_expiry_datetime|date,format:Y-m-d]]")])]), _c('br')], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }